import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-265ceadc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "camera-info__text camera-text" }
const _hoisted_3 = { class: "camera-text__name" }
const _hoisted_4 = {
  key: 0,
  class: "camera-text__error camera-text__error_doubt"
}
const _hoisted_5 = {
  key: 1,
  class: "camera-text__error"
}
const _hoisted_6 = { class: "camera-info__time" }
const _hoisted_7 = {
  key: 0,
  class: "camera-info__watchers"
}
const _hoisted_8 = { class: "camera-info__toggler camera-toggler" }
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  key: 1,
  class: "camera-info__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HollowDotsSpinner = _resolveComponent("HollowDotsSpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['camera', { 'camera_powered-off': _ctx.isPoweredOff }])
  }, [
    _createElementVNode("img", {
      src: _ctx.preview,
      class: "camera__image",
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(['camera__info', 'camera-info', { 'camera-info_reloaded': _ctx.reloaded }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.camera.name), 1),
        (!_ctx.isPoweredOff && _ctx.hasSingleError)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "?"))
          : _createCommentVNode("", true),
        (!_ctx.isPoweredOff && _ctx.hasErrors)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "✗"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.lastUpdated), 1),
      (_ctx.camera.watching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 👀 "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("input", {
          class: "camera-toggler__input",
          type: "checkbox",
          checked: !_ctx.isPoweredOff,
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePoweredOff && _ctx.togglePoweredOff(...args)))
        }, null, 40, _hoisted_9)
      ]),
      (_ctx.camera.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_HollowDotsSpinner, {
              "animation-duration": 1500,
              "dot-size": _ctx.dotSize,
              "dots-num": 3,
              color: "#ffffff"
            }, null, 8, ["dot-size"])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}