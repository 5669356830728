
import MenuPanel from './MenuPanel.vue';
import CameraGrid from './CameraGrid.vue';
import TopBar from './TopBar.vue';
import LoginScreen from './LoginScreen.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import when from 'vue-when';

export default defineComponent({
  name: 'App',
  components: {
    LoginScreen, CameraGrid, MenuPanel, TopBar,
  },
  setup() {
    const store = useStore();

    const authorized = ref(store.getters.authorized);
    const cameras = computed(() => store.state.cameras);
    const connectionLost = computed(() => store.state.connectionLost);

    const menuVisible = ref(false);

    function hideMenu() {
      menuVisible.value = false;
    }

    function showMenu() {
      menuVisible.value = true;
    }

    onMounted(async () => {
      // this is done to skip "loading cameras" screen after logging in
      if (!authorized.value) {
        await when(() => store.getters.authorized && cameras.value);
        authorized.value = true;
      }
    });

    return {
      authorized,
      cameras,
      connectionLost,
      hideMenu,
      menuVisible,
      showMenu,
    };
  },
});
