
import Slider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css'
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  components: {
    Slider
  },
  setup() {
    const store = useStore();

    const fontSize = computed({
      get() {
        return store.state.settings.fontSize;
      },
      set(value: number) {
        store.commit('setFontSize', value);
      },
    });

    return {
      fontSize,
    };
  },
});
