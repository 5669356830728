
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import { Camera } from '@/types';

const BASE_REPORT_URL = '/watchers-log';

export default defineComponent({
  name: 'CameraViewHistory',
  setup() {
    const store = useStore();
    const cameras = computed(() => store.state.cameras?.map((c: Camera) => ({
      link: `${BASE_REPORT_URL}?cameraName=${c.name}`,
      name: c.name,
    })));

    return {
      allLink: BASE_REPORT_URL,
      cameras,
    };
  },
});
