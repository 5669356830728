
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'LoginScreen',
  setup() {
    const invalid = ref(false);
    const password = ref('');
    const store = useStore();

    async function onSubmit(event: Event) {
      event.preventDefault();
      invalid.value = false;
      const success = await store.dispatch('login', password.value);
      if (!success) {
        invalid.value = true;
        password.value = '';
      } else {
        await store.dispatch('initialize');
      }
    }

    return {
      invalid,
      onSubmit,
      password,
    };
  },
});
