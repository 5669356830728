
import VueTypes from 'vue-types';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    camera: VueTypes.object.isRequired,
    imgSrc: VueTypes.string.isRequired,
    open: VueTypes.bool.def(false),
  },
  setup(props) {
    const loaded = ref(false);
    const frameSrc = computed(() => (props.camera.backend === 'ipeye' ?
      `https://ipeye.ru/ipeye_service/api/iframe.php?iframe_player=1&dev=${props.camera.id}&tupe=rtmp&autoplay=1&logo=1` :
      `https://rtsp.me/embed/${props.camera.id}`));

    function onFrameLoad() {
      loaded.value = true;
    }

    return {
      frameSrc,
      loaded,
      onFrameLoad,
    };
  },
});
