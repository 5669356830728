import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraGridCell = _resolveComponent("CameraGridCell")!
  const _component_FullscreenVideo = _resolveComponent("FullscreenVideo")!

  return (_openBlock(), _createElementBlock("div", {
    class: "camera-grid",
    style: _normalizeStyle({ fontSize: _ctx.fontSize })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (row, rowIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: rowIndex,
        class: "camera-grid__row"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (camera, cameraIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            camera
              ? (_openBlock(), _createBlock(_component_CameraGridCell, {
                  key: camera.id,
                  class: "camera-grid__camera",
                  camera: camera,
                  "dot-size": _ctx.dotSize,
                  onClick: ($event: any) => (_ctx.openFullscreen($event, camera))
                }, null, 8, ["camera", "dot-size", "onClick"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: cameraIndex,
                  class: "camera-grid__placeholder"
                }))
          ], 64))
        }), 256))
      ]))
    }), 128)),
    _createVNode(_Transition, {
      name: "fullscreen-transition",
      onEnter: _ctx.onTransitionEnter,
      onAfterEnter: _ctx.afterTransitionEnter,
      onLeave: _ctx.onTransitionLeave
    }, {
      default: _withCtx(() => [
        (_ctx.selectedCamera)
          ? (_openBlock(), _createBlock(_component_FullscreenVideo, {
              key: 0,
              class: "camera-grid__fullscreen-video",
              camera: _ctx.selectedCamera,
              open: _ctx.animationComplete,
              "img-src": _ctx.fullscreenPreview,
              onClose: _ctx.closeFullscreen
            }, null, 8, ["camera", "open", "img-src", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onEnter", "onAfterEnter", "onLeave"])
  ], 4))
}