
import { computed, defineComponent, Ref, ref, toRef } from 'vue';
import VueTypes from 'vue-types';
import moment from 'moment';
import { Camera } from '@/types';
import { useStore } from 'vuex';
import { useCameraPreview } from '@/helpers';
import { HollowDotsSpinner } from 'epic-spinners';

export default defineComponent({
  name: 'CameraGridCell',
  components: {
    HollowDotsSpinner,
  },
  props: {
    camera: VueTypes.object.isRequired,
    dotSize: VueTypes.number.isRequired,
  },
  setup(props) {
    const camera = toRef(props, 'camera') as Ref<Camera>;

    const store = useStore();
    const currentTime = computed(() => store.state.currentTime);
    const loadedVersion = ref(null as null | number);

    const hasErrors = computed(() => camera.value.failureCounter > 1);
    const hasSingleError = computed(() => camera.value.failureCounter === 1);
    const isPoweredOff = computed(() => camera.value.isPoweredOff);
    const lastUpdated = computed(() => {
      if (!camera.value.lastUpdated) {
        return 'не обновлялась';
      }
      return moment.min(camera.value.lastUpdated, currentTime.value).from(currentTime.value);
    });
    const preview = useCameraPreview(camera);
    const reloaded = computed(() => loadedVersion.value === camera.value.imageVersion && camera.value.imageVersion > 1);

    function onLoaded() {
      loadedVersion.value = camera.value.imageVersion;
    }

    async function togglePoweredOff() {
      await store.dispatch('toggleCameraPoweredOff', camera.value);
    }

    return {
      camera,
      hasErrors,
      hasSingleError,
      isPoweredOff,
      lastUpdated,
      onLoaded,
      preview,
      reloaded,
      togglePoweredOff,
    };
  },
});
