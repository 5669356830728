
import Settings from './Settings.vue';
import TopBar from './TopBar.vue';
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import CameraViewHistory from '@/WatcherLogLinks.vue';

export default defineComponent({
  components: {
    CameraViewHistory,
    Settings, TopBar
  },
  props: {
    ok: VueTypes.bool.def(true),
  },
});
