
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TopBar',
  props: {
    closable: VueTypes.bool.def(false),
    menuMode: VueTypes.bool.def(false),
    ok: VueTypes.bool.def(true),
  },
});
